import {
  Accordion,
  AccordionProps,
  Box,
  Checkbox,
  createStyles,
  Group,
  Paper,
  Stack,
  Text,
  Tooltip,
  TooltipProps,
} from '@mantine/core';
import React, { Dispatch, SetStateAction } from 'react';

import { SearchInput } from '@portals/core';
import { ReactComponent as Crown } from '@portals/icons/bold/crown-2.svg';
import { ReactComponent as TextAlignLeft } from '@portals/icons/linear/textalign-left.svg';
import { SupportedCommandType } from '@portals/types';

import { TransferListItem } from './transfer-list.types';

interface ItemRendererProps {
  item: TransferListItem<SupportedCommandType>;
}

export function ItemRenderer({ item }: ItemRendererProps) {
  const { classes, theme } = useStyles();

  return (
    <Box className={classes.itemRendererContainer}>
      <Text
        color="gray.9"
        size="sm"
        weight="bold"
        truncate
        title={item.name}
        fw={500}
      >
        {item.name}
      </Text>

      {item.premium ? (
        <Tooltip
          label="This command is currently linked to one or more licenses"
          data-testid="command-linked-to-many-licenses"
          withArrow
        >
          <Crown width={16} height={16} color={theme.colors.primary[4]} />
        </Tooltip>
      ) : null}
    </Box>
  );
}

interface TransferListItemsTitleRendererProps {
  transferredItems: Array<TransferListItem<SupportedCommandType>>;
}

export function TransferredItemsTitleRenderer({
  transferredItems,
}: TransferListItemsTitleRendererProps) {
  const { classes } = useStyles();

  return (
    <Text
      weight={600}
      className={classes.title}
      data-testid="included-commands-counter"
    >{`Commands included in this license (${transferredItems.length})`}</Text>
  );
}

interface TransferableItemsTitleRendererProps {
  transferableItemsCount: number;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

export function TransferableItemsTitleRenderer({
  transferableItemsCount,
  searchTerm,
  setSearchTerm,
}: TransferableItemsTitleRendererProps) {
  const { classes } = useStyles();

  return (
    <Group position="apart" noWrap>
      <Text color="gray.9" weight="600" className={classes.title}>
        {`Available commands (${transferableItemsCount})`}
      </Text>

      <SearchInput
        onClear={() => setSearchTerm('')}
        onChange={(event) => setSearchTerm(event.target.value)}
        value={searchTerm}
        size="xs"
        w={140}
        placeholder="Search..."
        data-testid="search-field-input"
      />
    </Group>
  );
}

interface TransferListItemWrapperWithAccordionProps<TItem extends object> {
  item: TransferListItem<TItem>;
  itemRenderer: (item: TransferListItem<TItem>) => React.ReactNode;
  isChecked: boolean;
  onCheck: (itemId: string) => void;
}

export function TransferListItemWrapperWithAccordion<TItem extends object>({
  item,
  itemRenderer,
  isChecked,
  onCheck,
}: TransferListItemWrapperWithAccordionProps<TItem>) {
  const { classes } = useStyles();

  return (
    <Accordion
      key={item.id}
      className={classes.optionWrapper}
      styles={accordionStyles}
    >
      <Accordion.Item value="command">
        <Box className={classes.accordionControlContent}>
          <Checkbox
            data-testid={'checkbox-' + item.name}
            checked={isChecked}
            onChange={() => onCheck(item.id)}
            onClick={(event) => event.stopPropagation()}
            mr="xs"
          />

          <Accordion.Control>{itemRenderer(item)}</Accordion.Control>
        </Box>

        <Accordion.Panel>
          <Text color="gray.7">{item['description']}</Text>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}

export function TransferListItemWrapper<TItem extends object>({
  item,
  itemRenderer,
  isChecked,
  onCheck,
}: TransferListItemWrapperWithAccordionProps<TItem>) {
  const { classes } = useStyles();

  return (
    <Box className={classes.optionWrapper} key={item.id} p="md">
      <Group position="apart" noWrap w="100%">
        <Box className={classes.optionContainer}>
          <Checkbox
            checked={isChecked}
            onChange={() => onCheck(item.id)}
            onClick={(event) => event.stopPropagation()}
            mr="xs"
          />

          {itemRenderer(item)}
        </Box>

        <Tooltip
          withArrow
          arrowSize={10}
          styles={tooltipStyles}
          label={
            <Paper>
              <Stack spacing="xs" p="md">
                <Text color="blue_gray.9" weight="bold">
                  Command description
                </Text>
                <Text color="blue_gray.7">{item.description}</Text>
              </Stack>
            </Paper>
          }
        >
          <Box className={classes.tooltipContent} w={32} h={32}>
            <TextAlignLeft width={16} height={16} />
          </Box>
        </Tooltip>
      </Group>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  itemRendererContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    alignItems: 'center',
  },

  title: {
    [theme.fn.smallerThan('lg')]: {
      fontSize: theme.fontSizes.sm,
    },
  },

  optionWrapper: {
    boxShadow: '0px 1px 5px 0px rgba(38, 50, 56, 0.13)',
    borderRadius: theme.radius.md,
    background: theme.white,
    flexWrap: 'nowrap',
    width: '100%',
  },

  optionContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    gap: theme.spacing.sm,
  },

  accordionControlContent: {
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr min-content',
    alignItems: 'center',
  },

  tooltipLabel: {
    backgroundColor: theme.white,
    border: 'none',
  },

  tooltipContent: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
    alignSelf: 'start',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },
}));

const accordionStyles: AccordionProps['styles'] = (theme) => ({
  chevron: {
    marginLeft: 0,
  },

  label: {
    padding: 0,
    marginRight: 4,
  },

  panel: {
    borderTop: `1px solid ${theme.colors.gray[3]}`,
    marginTop: theme.spacing.md,
  },

  control: {
    padding: 0,

    '&:hover': {
      backgroundColor: 'inherit',
    },
  },

  item: {
    padding: theme.spacing.md,
  },

  content: {
    marginTop: theme.spacing.md,
    padding: 0,
  },
});

const tooltipStyles: TooltipProps['styles'] = (theme) => ({
  tooltip: {
    backgroundColor: theme.white,
    borderRadius: theme.radius.lg,
    boxShadow: '0px 11px 23.9px 0px rgba(0, 0, 0, 0.19)',
  },
});
