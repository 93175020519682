import { UsePaginatedTableApiQuery } from '@portals/types';

import {
  ANALYTICS_DASHBOARD_CLAIMED_DEVICES_RATE_API_URL,
  ANALYTICS_DASHBOARD_SUB_ORGANIZATION_API_URL,
  ANALYTICS_DASHBOARD_ORGANIZATIONS_CREATION_RATE_API_URL,
  ANALYTICS_DASHBOARD_ORGANIZATION_API_URL,
  ANALYTICS_DASHBOARD_STATS_API_URL,
  analyticsDashboardQueryKeys,
} from './analytics-dashboard.constants';
import {
  AnalyticsSubOrganizationResponse,
  AnalyticsOrganizationResponse,
  ClaimedDeviceRateType,
  OrganizationCreationRateType,
  StatsType,
} from './analytics-dashboard.types';
import { useApiQuery } from '../../hooks';
import { usePaginatedTableApiQuery } from '../../utils';

export function useAnalyticsStats() {
  return useApiQuery<StatsType>(
    ANALYTICS_DASHBOARD_STATS_API_URL,
    analyticsDashboardQueryKeys.stats()
  );
}

export function useOrganizationsAnalyticsTable(
  tableState: UsePaginatedTableApiQuery<AnalyticsOrganizationResponse>['tableState'],
  columns: UsePaginatedTableApiQuery<AnalyticsOrganizationResponse>['columns']
) {
  return usePaginatedTableApiQuery<AnalyticsOrganizationResponse>({
    baseUrl: ANALYTICS_DASHBOARD_ORGANIZATION_API_URL,
    queryKey: [...analyticsDashboardQueryKeys.organizations(), tableState],
    tableState,
    columns,
    queryOptions: {
      staleTime: 0,
    },
  });
}

export function useSubOrganizationsAnalyticsTable(
  tableState: UsePaginatedTableApiQuery<AnalyticsSubOrganizationResponse>['tableState'],
  columns: UsePaginatedTableApiQuery<AnalyticsSubOrganizationResponse>['columns']
) {
  return usePaginatedTableApiQuery<AnalyticsSubOrganizationResponse>({
    baseUrl: ANALYTICS_DASHBOARD_SUB_ORGANIZATION_API_URL,
    queryKey: [...analyticsDashboardQueryKeys.subOrganizations(), tableState],
    tableState,
    columns,
    queryOptions: {
      staleTime: 0,
    },
  });
}

export function useAnalyticsCustomerCreationRate() {
  return useApiQuery<OrganizationCreationRateType>(
    ANALYTICS_DASHBOARD_ORGANIZATIONS_CREATION_RATE_API_URL,
    analyticsDashboardQueryKeys.customerCreationRate()
  );
}

export function useAnalyticsClaimedDeviceRate() {
  return useApiQuery<ClaimedDeviceRateType>(
    ANALYTICS_DASHBOARD_CLAIMED_DEVICES_RATE_API_URL,
    analyticsDashboardQueryKeys.claimedDeviceRate()
  );
}
