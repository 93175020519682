import React from 'react';

import {
  MOCK_CHILDREN_POINTS,
  MOCK_PARENT_POINT,
} from '../form/device-location-form.consts';
import { DeviceLocationWidgetFormType } from '../form/device-location-form.types';
import { MockChildDeviceLocationMapMarker } from '../form/MockChildDeviceLocationMapMarker';
import { SuperClusterPoint } from '../widget/device-location-widget.types';
import { DeviceLocationMarker } from '../widget/DeviceLocationMapMarker';

export const getParentMockPointData = (
  formValues: DeviceLocationWidgetFormType
) => ({
  type: 'Feature' as const,
  id: 'parent',
  geometry: {
    type: 'Point' as const,
    coordinates: [MOCK_PARENT_POINT.lng, MOCK_PARENT_POINT.lat],
  },
  properties: {
    id: 'parent',
    cluster: false,
    type: 'main',
    name: 'Parent',
    status: 'online',
    coordinates: MOCK_PARENT_POINT,

    renderSinglePoint: () => (
      <DeviceLocationMarker
        key="parent"
        title="Parent Location"
        showLocationName={formValues.show_location_name}
        color={formValues.color}
        isDeviceOffline={false}
        lastUpdateTimestamp="1"
        lat={MOCK_PARENT_POINT.lat}
        lng={MOCK_PARENT_POINT.lng}
      />
    ),
  },
});

export const getMockMapPointsFromFormValues = (
  formValues: DeviceLocationWidgetFormType,
  activeDimensionId?: string
): SuperClusterPoint[] => {
  if (!formValues.dimensions || formValues.dimensions.length === 0) {
    return [getParentMockPointData(formValues)];
  }

  const currentDimension =
    formValues.dimensions.find(
      (dimension) => dimension.id === activeDimensionId
    ) || formValues.dimensions[0];

  const dimensionConditionsLength = currentDimension.coloringRules.length;

  const childrenPoints = MOCK_CHILDREN_POINTS.slice(
    0,
    dimensionConditionsLength
  );

  const childrenMockPointsData = childrenPoints.map((point, index) => {
    const currentCondition = currentDimension?.coloringRules[index];

    return {
      type: 'Feature' as const,
      id: currentCondition.id,
      geometry: {
        type: 'Point' as const,
        coordinates: [point.lng, point.lat],
      },
      properties: {
        id: currentCondition.id,
        cluster: false,
        type: 'child',
        name: 'Child',
        status: 'online',
        coordinates: point,

        renderSinglePoint: () => {
          return (
            <MockChildDeviceLocationMapMarker
              key={currentCondition.id}
              color={
                currentCondition?.color ||
                currentDimension?.coloringRules[0].color
              }
              tooltipLabel={currentCondition?.value}
              lat={point.lat}
              lng={point.lng}
            />
          );
        },
      },
    };
  });

  return [getParentMockPointData(formValues), ...childrenMockPointsData];
};
