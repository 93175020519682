import { Divider, Input, Stack, Switch, SwitchProps } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { DeviceLocationWidgetFormType } from './device-location-form.types';
import { ColorSelector } from '../../common/ColorSelector';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

export interface GeneralDeviceLocationWidgetFormProps {
  form: UseFormReturnType<DeviceLocationWidgetFormType>;
  onAddCustomColor: OnAddCustomColorFn | undefined;
  colors: Array<WidgetColorType> | undefined;
}

export function GeneralTabDeviceLocationWidgetForm({
  form,
  onAddCustomColor,
  colors,
}: GeneralDeviceLocationWidgetFormProps) {
  return (
    <Stack spacing="xl">
      <Switch
        labelPosition="left"
        label="Show title"
        checked={form.values.show_location_name}
        onChange={(event) =>
          form.setFieldValue('show_location_name', event.target.checked)
        }
        styles={switchStyles}
      />
      <Divider />

      <Input.Wrapper label="Color">
        <ColorSelector
          onAddCustomColor={onAddCustomColor}
          colors={colors}
          selectedColor={form.values.color}
          onPresetColorSelect={(color) => form.setFieldValue('color', color)}
        />
      </Input.Wrapper>
    </Stack>
  );
}

const switchStyles: SwitchProps['styles'] = {
  body: {
    justifyContent: 'space-between',
  },
};
