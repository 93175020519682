import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import {
  toastrError,
  toastrSuccess,
  toastrWarning,
} from '@portals/redux/actions/toastr';
import { RESULTS_PER_PAGE, UsePaginatedTableApiQuery } from '@portals/types';

import { DEVICES_API_URL, devicesQueryKeys } from './devices.constants';
import { DeviceStateHistory, DeviceType } from './devices.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import {
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';

export function usePaginatedDevicesData({ pageIndex }: { pageIndex: number }) {
  return usePaginatedTableApiQuery<DeviceType>({
    baseUrl: DEVICES_API_URL,
    queryKey: devicesQueryKeys.list,
    tableState: {
      pageIndex,
      pageSize: RESULTS_PER_PAGE,
    },
  });
}

export function usePaginatedDevicesTableData(
  tableState: UsePaginatedTableApiQuery<DeviceType>['tableState'],
  columns: UsePaginatedTableApiQuery<DeviceType>['columns']
) {
  return usePaginatedTableApiQuery<DeviceType>({
    baseUrl: DEVICES_API_URL,
    queryKey: [...devicesQueryKeys.list, tableState],
    tableState,
    columns,
    queryOptions: { staleTime: 0 },
  });
}

interface UseDeviceUsageBasedStateHistoriesParams {
  deviceId: string;
  from: Date;
  to: Date;
}
export function useDeviceUsageBasedStateHistories({
  deviceId,
  from,
  to,
}: UseDeviceUsageBasedStateHistoriesParams) {
  const buildUrl = () => {
    let url = `${DEVICES_API_URL}/${deviceId}/usage_based_state_histories`;
    const queryString = new URLSearchParams();
    queryString.append('from', from.toISOString());
    queryString.append('to', to.toISOString());

    url += `?${queryString.toString()}`;

    return url;
  };

  return useApiQuery<DeviceStateHistory[]>(
    buildUrl(),
    devicesQueryKeys.usageBasedStateHistories(deviceId),
    { staleTime: 0, enabled: !!deviceId }
  );
}

interface UseUpdateDeviceParams {
  deviceId: string;
  partner_note: string;
}

export function useUpdateDevice() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: DEVICES_API_URL,
    method: 'PUT',
  });

  return useMutation<DeviceType, ServerError, UseUpdateDeviceParams>({
    mutationFn: ({ deviceId, partner_note }) =>
      fetchApiRequest(`${url}/${deviceId}`, {
        ...options,
        body: JSON.stringify({ partner_note }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(devicesQueryKeys.list);

      dispatch(toastrSuccess('Note saved'));
    },
    meta: {
      mutationName: 'useUpdateDevice',
      baseUrl: `${DEVICES_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

interface UseBulkDeleteDevicesParams {
  device_ids: Array<string>;
}

export function useBulkDeleteDevices() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<void, { error: any }, UseBulkDeleteDevicesParams>({
    mutationFn: ({ device_ids: ids }) =>
      fetchApiRequest(`${url}/${DEVICES_API_URL}/bulk_destroy`, {
        ...options,
        body: JSON.stringify({ ids }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(devicesQueryKeys.list);

      dispatch(toastrSuccess('Devices successfully removed'));
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useBulkDeleteDevices',
      baseUrl: `${DEVICES_API_URL}/bulk_destroy`,
      method: 'POST',
    },
  });
}

export function useAssignDevices() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: `${DEVICES_API_URL}/bulk_assign`,
    method: 'POST',
  });

  return useMutation({
    mutationFn: (assignmentData: {
      device_ids: Array<string>;
      organization_id: string;
    }) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(assignmentData),
      }),
    onSuccess: (data) => {
      // Re-fetch devices data
      queryClient.invalidateQueries(devicesQueryKeys.list);

      if (data.warning) {
        dispatch(toastrWarning(data.warning));
      }

      dispatch(toastrSuccess('Device successfully assigned'));
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useAssignDevices',
      baseUrl: `${DEVICES_API_URL}/bulk_assign`,
      method: 'POST',
    },
  });
}

export function useExportDevicesToCsv() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${DEVICES_API_URL}/export_csv`,
    method: 'GET',
  });

  return useMutation<Blob, ServerError, void>({
    mutationFn: async () => {
      const response = await fetch(url, options);
      return await response.blob();
    },
    onSuccess: (blob) => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);

      a.download = `devices.csv`;

      a.click();

      dispatch(toastrSuccess('Devices exported'));
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}
