import { API } from '@portals/redux';
import { updateData, updateNormalizedData } from '@portals/redux/actions/data';
import { TICKET_DEVICE_SCHEMA } from '@portals/redux/schemas';

export const updateConfig = (ticketId, config) => ({
  type: API,
  payload: {
    method: 'PUT',
    url: `ui/partner/tickets/${ticketId}/device`,
    data: { config },
    success: updateNormalizedData('ticket_device'),
    normalize: TICKET_DEVICE_SCHEMA,
    toastr: 'Update configuration',
  },
});

export const getTelemetries = (deviceId, limit = null) => ({
  type: API,
  payload: {
    url: `ui/partner/telemetries/${deviceId}/${limit ? '?limit=' + limit : ''}`,
    success: updateData('telemetries'),
    name: 'getTelemetries',
  },
});

export const sendCommand = (ticketId, data, extraActions) => ({
  type: API,
  payload: {
    method: 'POST',
    url: `ui/partner/tickets/${ticketId}/device/send_command`,
    data,
    success: [extraActions],
    normalize: TICKET_DEVICE_SCHEMA,
    toastr: 'Command sent',
  },
});

export const cancelCommand = (ticketId, command_id) => ({
  type: API,
  payload: {
    method: 'POST',
    url: `ui/partner/tickets/${ticketId}/device/cancel_command`,
    data: { command_id },
    normalize: TICKET_DEVICE_SCHEMA,
    toastr: 'Cancel command',
  },
});
