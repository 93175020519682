export const MAX_DIMENSIONS = 3;
export const MAX_COLORING_RULES_PER_DIMENSION = 30;

export const MOCK_PARENT_POINT: { lat: number; lng: number } = {
  lat: 47.5515,
  lng: -101.002,
};

export const MOCK_CHILDREN_POINTS: Array<{ lat: number; lng: number }> = [
  { lat: 32.3182, lng: -86.9023 }, // Alabama
  { lat: 34.0489, lng: -111.0937 }, // Arizona
  { lat: 35.201, lng: -91.8318 }, // Arkansas
  { lat: 36.7783, lng: -119.4179 }, // California
  { lat: 39.5501, lng: -105.7821 }, // Colorado
  { lat: 41.6032, lng: -73.0877 }, // Connecticut
  { lat: 38.9108, lng: -75.5277 }, // Delaware
  { lat: 27.9944, lng: -81.7603 }, // Florida
  { lat: 32.1656, lng: -82.9001 }, // Georgia
  { lat: 19.8968, lng: -155.5828 }, // Hawaii
  { lat: 44.0682, lng: -114.742 }, // Idaho
  { lat: 40.6331, lng: -89.3985 }, // Illinois
  { lat: 40.2672, lng: -86.1349 }, // Indiana
  { lat: 41.878, lng: -93.0977 }, // Iowa
  { lat: 39.0119, lng: -98.4842 }, // Kansas
  { lat: 37.8393, lng: -84.27 }, // Kentucky
  { lat: 30.9843, lng: -91.9623 }, // Louisiana
  { lat: 45.2538, lng: -69.4455 }, // Maine
  { lat: 39.0458, lng: -76.6413 }, // Maryland
  { lat: 42.4072, lng: -71.3824 }, // Massachusetts
  { lat: 44.3148, lng: -85.6024 }, // Michigan
  { lat: 46.7296, lng: -94.6859 }, // Minnesota
  { lat: 32.3547, lng: -89.3985 }, // Mississippi
  { lat: 37.9643, lng: -91.8318 }, // Missouri
  { lat: 46.8797, lng: -110.3626 }, // Montana
  { lat: 41.4925, lng: -99.9018 }, // Nebraska
  { lat: 38.8026, lng: -116.4194 }, // Nevada
  { lat: 43.1939, lng: -71.5724 }, // New Hampshire
  { lat: 40.0583, lng: -74.4057 }, // New Jersey
  { lat: 34.5199, lng: -105.8701 }, // New Mexico
  { lat: 43.2994, lng: -74.2179 }, // New York
  { lat: 35.7596, lng: -79.0193 }, // North Carolina
  { lat: 65.640775, lng: -127.170214 }, // Sahtu Region Canada
];
