import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  Group,
  Modal,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { isEmpty, sumBy, values as valuesFn } from 'lodash/fp';
import React, { useRef } from 'react';

import { useFeatureFlags } from '@portals/api/partners';
import { SortableDndItem } from '@portals/core';
import { isPartnerFeatureOn, ModalProps } from '@portals/framework';
import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import { ReactComponent as Drag } from '@portals/icons/linear/drag.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import {
  FieldTypeEnum,
  SupportedCommandFieldType,
  SupportedCommandType,
} from '@portals/types';
import { suppressPropagation } from '@portals/utils';

import {
  CommandsTypesEnum,
  COMMAND_TYPES_DATA,
  COMMAND_TYPES,
} from '../constants';

interface CustomFieldFormProps
  extends ModalProps<{
    onSubmit: (field: SupportedCommandFieldType, fieldName?: string) => void;
    custom_fields: SupportedCommandType['custom_fields'];
    field?: Partial<SupportedCommandFieldType>;
  }> {}

interface FormValues {
  type: string;
  field: string;
  label: string;
  options: Array<{ value: string; label: string; order: number }>;
  isRequired: boolean;
  isHidden: boolean;
  min: number;
  max: number;
  path: string;
  typeName: string;
}

const getInitialOptionsList = (field: Partial<SupportedCommandFieldType>) => {
  if (isEmpty(field.options)) {
    return [
      {
        value: '',
        label: '',
        order: 0,
      },
    ];
  }

  return valuesFn(field.options).sort((a, b) => a.order - b.order);
};

export function CustomFieldForm({
  closeMe,
  data: { onSubmit, field = {}, custom_fields = [] },
}: CustomFieldFormProps) {
  const { classes } = useStyles();

  const featureFlags = useFeatureFlags();

  const allowHiddenCustomField = isPartnerFeatureOn(
    featureFlags,
    'supported_command_hidden_custom_field'
  );

  const initialFieldName = useRef(field.name);
  const form = useForm<FormValues>({
    initialValues: {
      type: field.type || '',
      typeName: field.typeName || '',
      field: field.name || '',
      label: (field.title as string) || '',
      options: getInitialOptionsList(field),
      isRequired: !!field.required,
      isHidden: !!field?.hidden,
      min: field.inputProps?.min || 0,
      max: field.inputProps?.max || 0,
      path: field.path || '',
    },

    clearInputErrorOnChange: true,

    validate: {
      field: (value) => {
        const isNewField = isEmpty(field);
        const nameAppearancesSum = sumBy(
          ({ name }) => (name === value ? 1 : 0),
          custom_fields
        );
        const isDuplicated =
          isNewField || initialFieldName.current !== value
            ? nameAppearancesSum > 0
            : nameAppearancesSum > 1;

        return isDuplicated
          ? `Field with "${value}" name already exists`
          : null;
      },

      options: {
        value: (fieldValue, allValues) =>
          sumBy(
            ({ value }) => (fieldValue === value ? 1 : 0),
            allValues.options
          ) > 1
            ? `Options values should be unique`
            : null,
      },
    },
  });

  const onSubmitWrapper = (values: typeof form.values) => {
    if (!values.type) {
      form.setFieldError('type', 'Type is required');
      return;
    }

    const fieldType: SupportedCommandFieldType = {
      name: values.field,
      title: values.label || values.field,
      type: values.type as FieldTypeEnum,
      required: values.isRequired,
      typeName: values.typeName,
      hidden: values.isHidden,
    };

    if (
      values.typeName === CommandsTypesEnum.StaticListSingle ||
      values.typeName === CommandsTypesEnum.StaticListMulti
    ) {
      // convert options array into an object with `value`s as keys
      fieldType.options = values.options.reduce((acc, option, index) => {
        acc[option.value] = { ...option, order: index };

        return acc;
      }, {});
    } else if (values.typeName === CommandsTypesEnum.Number) {
      fieldType.inputProps = {
        min: form.values.min,
        max: form.values.max,
      };
    } else if (values.typeName === CommandsTypesEnum.Boolean) {
      // Checkbox field cannot be required
      fieldType.required = false;
    } else if (
      values.typeName === CommandsTypesEnum.DynamicListSingle ||
      values.typeName === CommandsTypesEnum.DynamicListMulti
    ) {
      fieldType.path = values.path;
    }

    onSubmit(fieldType, initialFieldName.current);
    closeMe();
  };

  const onMove = (dragIndex: number, hoverIndex: number) => {
    form.reorderListItem('options', { from: dragIndex, to: hoverIndex });
  };

  return (
    <Modal title="Add custom field" opened onClose={closeMe}>
      <form onSubmit={suppressPropagation(form.onSubmit(onSubmitWrapper))}>
        <Stack>
          <Group>
            {form.values.typeName ===
            COMMAND_TYPES[CommandsTypesEnum.Boolean].value ? null : (
              <Checkbox
                label="Required"
                checked={form.values.isRequired}
                {...form.getInputProps('isRequired')}
              />
            )}

            {allowHiddenCustomField && (
              <Checkbox
                label="Hidden"
                checked={form.values.isHidden}
                {...form.getInputProps('isHidden')}
              />
            )}
          </Group>

          <Group grow>
            <TextInput
              required
              data-testid="field-key-input"
              label="Field key"
              placeholder="is_active"
              {...form.getInputProps('field')}
            />

            <TextInput
              data-testid="label-input"
              label="Label"
              placeholder="Active"
              {...form.getInputProps('label')}
            />
          </Group>

          <Select
            required
            withinPortal
            data-testid="field-type-input"
            label="Field type"
            data={COMMAND_TYPES_DATA}
            value={form.values.typeName}
            onChange={(value) => {
              form.setFieldValue('type', COMMAND_TYPES[value]?.value);
              form.setFieldValue('typeName', value);
            }}
          />

          {form.values.typeName === CommandsTypesEnum.Number ? (
            <Group grow>
              <NumberInput
                label="Min"
                data-testid="minimum-value-input"
                {...form.getInputProps('min')}
              />

              <NumberInput
                label="Max"
                data-testid="maximum-value-input"
                {...form.getInputProps('max')}
              />
            </Group>
          ) : null}

          {form.values.typeName === CommandsTypesEnum.StaticListSingle ||
          form.values.typeName === CommandsTypesEnum.StaticListMulti ? (
            <Stack spacing="md">
              <Divider variant="dashed" label="OPTIONS" />

              {form.values.options.map(({ value, label }, index) => (
                <SortableDndItem
                  key={index}
                  dndTypeIdentifier="option-field"
                  itemIndex={index}
                  itemUniqueId={index}
                  onMove={onMove}
                >
                  <Group align="center" p={4} sx={{ position: 'relative' }}>
                    <Box
                      c="gray.3"
                      sx={{
                        transform: 'rotate(-90deg) translateY(-40%)',
                        transformOrigin: 'right center',
                      }}
                    >
                      <Drag />
                    </Box>

                    <TextInput
                      required
                      data-testid="option-value-input"
                      label="Value"
                      sx={{ flex: '1 !important' }}
                      {...form.getInputProps(`options.${index}.value`)}
                    />

                    <TextInput
                      data-testid="option-label-input"
                      label="Label"
                      required
                      sx={{ flex: '1 !important' }}
                      {...form.getInputProps(`options.${index}.label`)}
                    />

                    <Box
                      pt="lg"
                      sx={
                        index === 0
                          ? { visibility: 'hidden', pointerEvents: 'none' }
                          : {}
                      }
                    >
                      <ActionIcon
                        onClick={() => form.removeListItem('options', index)}
                        sx={{
                          svg: { width: 16, height: 16 },
                        }}
                      >
                        <Trash />
                      </ActionIcon>
                    </Box>
                  </Group>
                </SortableDndItem>
              ))}

              <Group position="center" mt="md">
                <Button
                  variant="default"
                  color="blue_gray"
                  leftIcon={<AddSquare />}
                  onClick={() =>
                    form.insertListItem('options', {
                      value: '',
                      label: '',
                      order: form.values.options.length + 1,
                    })
                  }
                  sx={{
                    svg: {
                      height: 16,
                      width: 16,
                    },
                  }}
                >
                  Add option
                </Button>
              </Group>
            </Stack>
          ) : null}

          {form.values.typeName === CommandsTypesEnum.DynamicListSingle ||
          form.values.typeName === CommandsTypesEnum.DynamicListMulti ? (
            <Stack>
              <Stack spacing="xs" className={classes.dynamicListContainer}>
                <Text size="md" color="gray.9" weight={600}>
                  Dynamic list
                </Text>

                <Stack spacing={0}>
                  <Text>
                    Dynamic list allows the command's parameters to be
                    dynamically generated based on the device's current
                    configuration.
                  </Text>
                  <Anchor
                    href="https://dev.xyte.io/docs/custom-commands"
                    target="_blank"
                  >
                    Learn More
                  </Anchor>
                </Stack>
              </Stack>

              <TextInput
                label="Path"
                data-testid="dynamic-list-path-field-input"
                placeholder="Example: details.video_settings[1].supported_inputs"
                required
                {...form.getInputProps('path')}
              />
            </Stack>
          ) : null}

          <Group position="right">
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>

            <Button type="submit" data-testid="add-custom-field-submit-button">
              {isEmpty(field) ? 'Add' : 'Update'}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  dynamicListContainer: {
    padding: theme.spacing.xl,
    backgroundColor: theme.colors.blue_accent[0],
    borderRadius: theme.radius.lg,
  },
}));
