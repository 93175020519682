import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  Modal,
  NumberInput,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  SwitchProps,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { map, uniq } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DeviceModelDetailsType,
  useCreateDeviceModel,
  UseCreateDeviceModelRequestPayload,
  useDeviceModelApiVersions,
} from '@portals/api/partners';
import { IconPicker } from '@portals/core';
import { DEVICE_MODEL_ICONS, ModalProps } from '@portals/framework';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';
import {
  AuthMethodType,
  CommunicationProtocolType,
  DeviceModelIconName,
} from '@portals/types';
import { formatNumber } from '@portals/utils';

import { DASHBOARD_PATHS } from '../../routes/dashboard/dashboard-paths.constants';

const DEFAULT_TTL_THRESHOLD = 15;
const MAX_TTL_THRESHOLD = 99_999;
const MIN_TTL_THRESHOLD = 1;

export interface CreateDeviceModelModalProps
  extends ModalProps<{
    onCreationSuccess?: (newDeviceModel: DeviceModelDetailsType) => void;
  }> {}

export function CreateDeviceModelModal({
  closeMe,
  data: { onCreationSuccess },
}: CreateDeviceModelModalProps) {
  const navigate = useNavigate();

  const createDeviceModel = useCreateDeviceModel();
  const apiVersions = useDeviceModelApiVersions();

  const isLoading = createDeviceModel.isLoading || apiVersions.isLoading;

  const form = useForm<UseCreateDeviceModelRequestPayload>({
    initialValues: {
      name: '',
      sku: '',
      auth_method: AuthMethodType.CloudId,
      communication_protocol: CommunicationProtocolType.HTTPS,
      current_api_version: null,
      device_type: 'physical',
      ttl_in_minutes: DEFAULT_TTL_THRESHOLD,
      user_settings: {},

      visible: true,

      create_reboot_command: true,
      create_firmware_upgrade_command: true,
      create_dump_command: true,

      enable_configuration_device_tab: true,
      enable_state_device_tab: true,
      enable_telemetries_device_tab: true,
      enable_details_device_tab: true,
      enable_events_device_tab: false,
      enable_files_device_tab: false,
    },
  });

  const [isTtlThresholdActive, setIsTtlThresholdActive] = useState(
    form.values.ttl_in_minutes !== null
  );

  const apiVersionsOptions = useMemo(() => {
    if (apiVersions.isLoading || !apiVersions.data) return [];

    return map(
      (version) => ({ value: version, label: version }),
      uniq([apiVersions.data?.latest, ...apiVersions.data.used])
    );
  }, [apiVersions.data, apiVersions.isLoading]);

  if (
    !form.values.current_api_version &&
    apiVersions.isFetched &&
    apiVersions.data
  ) {
    const { latest: latestAvailable, used: usedByModels } = apiVersions.data;

    form.setFieldValue(
      'current_api_version',
      usedByModels[0] || latestAvailable
    );
  }

  if (isTtlThresholdActive && form.values.ttl_in_minutes === null) {
    form.setFieldValue('ttl_in_minutes', DEFAULT_TTL_THRESHOLD);
  }

  const onSubmit = (values: typeof form.values) => {
    const requestPayload = { ...values };

    if (!isTtlThresholdActive) {
      requestPayload.ttl_in_minutes = null;
    }

    if (!requestPayload.sku) {
      requestPayload.sku = null;
    }

    createDeviceModel.mutate(requestPayload, {
      onSuccess: (newDeviceModel) => {
        if (onCreationSuccess) {
          onCreationSuccess(newDeviceModel);
        } else {
          navigate(DASHBOARD_PATHS.dynamicPaths.deviceModel(newDeviceModel.id));
        }

        closeMe();
      },
    });
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      size="xl"
      title={
        <Text data-testid="configure-model-modal-title">Create New Model</Text>
      }
    >
      <LoadingOverlay visible={isLoading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xl" py="md">
          <Grid>
            <Grid.Col span={1}>
              <Input.Wrapper label="Icon">
                <IconPicker<DeviceModelIconName>
                  icons={DEVICE_MODEL_ICONS}
                  position="bottom"
                  triggerSize={36}
                  iconName={form.values.user_settings?.icon || 'Server'}
                  onChange={(iconName) =>
                    form.setFieldValue('user_settings.icon', iconName)
                  }
                />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span="auto">
              <TextInput
                required
                label="Model name"
                data-testid="model-name-input"
                size="sm"
                data-autofocus
                maxLength={60}
                {...form.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col span="auto">
              <TextInput
                label="SKU (optional)"
                data-testid="model-sku-input"
                size="sm"
                {...form.getInputProps('sku')}
              />
            </Grid.Col>
          </Grid>

          <Divider variant="dashed" />

          <SimpleGrid cols={2}>
            <Select
              required
              label="Authentication method"
              rightSection={
                <Tooltip
                  label="See documentation"
                  position="right"
                  withinPortal={false}
                >
                  <Box
                    sx={(theme) => ({
                      cursor: 'pointer',
                      color: theme.colors.gray[6],
                    })}
                    onClick={() =>
                      window.open(
                        'https://dev.xyte.io/reference/set-authentication-method',
                        '_blank'
                      )
                    }
                  >
                    <QuestionCircle />
                  </Box>
                </Tooltip>
              }
              withinPortal={false}
              data={[
                { value: AuthMethodType.CloudId, label: 'Cloud ID' },
                {
                  value: AuthMethodType.MacSN,
                  label: 'MAC & Serial Number',
                },
                {
                  value: AuthMethodType.PubKey,
                  label: 'Pub Key',
                  disabled: true,
                },
                {
                  value: AuthMethodType.x509,
                  label: 'X.509 Certificate',
                  disabled: true,
                },
              ]}
              {...form.getInputProps('auth_method')}
            />

            <Select
              label="Communication protocol"
              withinPortal={false}
              required
              data={[
                {
                  value: CommunicationProtocolType.HTTPS,
                  label: 'HTTPS',
                },
                {
                  value: CommunicationProtocolType.MQTT,
                  label: 'MQTT',
                },
                {
                  value: CommunicationProtocolType.VPRO,
                  label: 'vPro (Coming Soon)',
                  disabled: true,
                },
              ]}
              {...form.getInputProps('communication_protocol')}
            />
          </SimpleGrid>

          <SimpleGrid cols={2}>
            <Stack>
              <Select
                label="Device type"
                rightSection={
                  <Tooltip
                    label="See documentation"
                    position="right"
                    withinPortal={false}
                  >
                    <Box
                      sx={(theme) => ({
                        cursor: 'pointer',
                        color: theme.colors.gray[6],
                      })}
                      onClick={() =>
                        window.open(
                          'https://dev.xyte.io/reference/device-types',
                          '_blank'
                        )
                      }
                    >
                      <QuestionCircle />
                    </Box>
                  </Tooltip>
                }
                withinPortal={false}
                required
                data={[
                  { value: 'physical', label: 'Physical' },
                  { value: 'digital', label: 'Digital' },
                  { value: 'application', label: 'Application' },
                ]}
                {...form.getInputProps('device_type')}
              />
            </Stack>

            <Select
              label="XYTE API Version"
              disabled={apiVersionsOptions?.length === 1}
              withinPortal={false}
              required
              data={apiVersionsOptions}
              rightSection={
                <Tooltip
                  label="We advise to select the latest API version that your device firmware supports"
                  multiline
                  width={300}
                  position="top"
                >
                  <Box
                    sx={(theme) => ({
                      cursor: 'pointer',
                      color: theme.colors.gray[6],
                    })}
                  >
                    <QuestionCircle />
                  </Box>
                </Tooltip>
              }
              {...form.getInputProps('current_api_version')}
            />
          </SimpleGrid>

          <Input.Wrapper
            label={
              <Group>
                <Text>Offline detection threshold</Text>

                <Switch
                  checked={isTtlThresholdActive}
                  data-testid="offline-detection-threshold-switch"
                  onChange={(event) =>
                    setIsTtlThresholdActive(event.target.checked)
                  }
                />
              </Group>
            }
            description={
              <Text mb="sm">
                When no data is sent from the device for this period (in
                minutes) the device will be marked as "offline" (between{' '}
                {MIN_TTL_THRESHOLD} and {formatNumber(MAX_TTL_THRESHOLD)})
              </Text>
            }
          >
            <Group spacing="xs">
              <NumberInput
                disabled={!isTtlThresholdActive}
                w={100}
                type="number"
                min={MIN_TTL_THRESHOLD}
                max={MAX_TTL_THRESHOLD}
                required={isTtlThresholdActive}
                value={form.values.ttl_in_minutes ?? undefined}
                onChange={(value) => {
                  if (value === '') {
                    form.setFieldValue('ttl_in_minutes', undefined);
                  } else {
                    form.setFieldValue('ttl_in_minutes', value);
                  }
                }}
              />

              <Text color={isTtlThresholdActive ? 'gray.7' : 'gray.4'}>
                minutes
              </Text>
            </Group>
          </Input.Wrapper>

          <Divider variant="dashed" />

          <Input.Wrapper
            label={<Text mb="xs">Enabled tabs in device view</Text>}
          >
            <Stack align="start">
              <Switch
                styles={switchStyles}
                size="xs"
                label="Configuration"
                {...form.getInputProps('enable_configuration_device_tab', {
                  type: 'checkbox',
                })}
              />

              <Switch
                styles={switchStyles}
                size="xs"
                label="State"
                {...form.getInputProps('enable_state_device_tab', {
                  type: 'checkbox',
                })}
              />

              <Switch
                styles={switchStyles}
                size="xs"
                label="Telemetries"
                {...form.getInputProps('enable_telemetries_device_tab', {
                  type: 'checkbox',
                })}
              />

              <Switch
                styles={switchStyles}
                size="xs"
                label="Details"
                {...form.getInputProps('enable_details_device_tab', {
                  type: 'checkbox',
                })}
              />

              <Switch
                styles={switchStyles}
                size="xs"
                label="Events"
                {...form.getInputProps('enable_events_device_tab', {
                  type: 'checkbox',
                })}
              />

              <Switch
                styles={switchStyles}
                size="xs"
                label="Files"
                {...form.getInputProps('enable_files_device_tab', {
                  type: 'checkbox',
                })}
              />
            </Stack>
          </Input.Wrapper>

          <Divider variant="dashed" />

          <Input.Wrapper
            label={
              <Group align="center" spacing="xs" mb="xs">
                <Text>Suggested commands</Text>

                <Tooltip
                  label={
                    <Text>
                      These pre-defined suggested commands are a collection of
                      commonly used actions for devices. By selecting the
                      checkboxes, you can automatically add them to your device
                      model
                    </Text>
                  }
                  multiline
                  width={300}
                  position="right"
                >
                  <Box color="gray.6">
                    <QuestionCircle />
                  </Box>
                </Tooltip>
              </Group>
            }
          >
            <Flex gap="xl">
              <Checkbox
                label="Reboot"
                {...form.getInputProps('create_reboot_command', {
                  type: 'checkbox',
                })}
              />

              <Checkbox
                label="Upgrade Firmware"
                {...form.getInputProps('create_firmware_upgrade_command', {
                  type: 'checkbox',
                })}
              />

              <Checkbox
                label="Request Dump"
                {...form.getInputProps('create_dump_command', {
                  type: 'checkbox',
                })}
              />
            </Flex>
          </Input.Wrapper>

          <Divider />

          <Checkbox
            label="Show this model in the device claiming catalog"
            {...form.getInputProps('visible', { type: 'checkbox' })}
          />

          <Divider />
        </Stack>

        <Group position="right" pt="xl">
          <Button
            variant="outline"
            data-testid="configure-model-modal-close-button"
            onClick={closeMe}
          >
            Close
          </Button>

          <Button
            color="primary"
            type="submit"
            disabled={!form.values.name || isLoading}
            data-testid="configure-model-modal-submit-button"
          >
            Create new model
          </Button>
        </Group>
      </form>
    </Modal>
  );
}

const switchStyles: SwitchProps['styles'] = {
  body: {
    display: 'grid',
    flexDirection: 'row-reverse',
    gridTemplateColumns: '100px max-content',
  },
  labelWrapper: {
    order: 1,
  },
  label: {
    paddingLeft: 0,
  },
  track: {
    order: 2,
  },
};
