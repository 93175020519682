import { WidgetColorType } from '../../widgets.types';

export interface ColoringRule {
  id: string;
  value: string;
  color: WidgetColorType;
}

export interface Dimension {
  id: string;
  name: string;
  telemetry_key: string;
  coloringRules: ColoringRule[];
}

export interface DeviceLocationWidgetFormType {
  name: string;
  show_location_name: boolean;
  color: WidgetColorType;
  dimensions?: Dimension[];
}

export enum DeviceLocationWidgetFormTabs {
  General = 'General',
  ChildDevices = 'Child devices',
}
