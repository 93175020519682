import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  Modal,
  NumberInput,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  SwitchProps,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { map, uniq } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DeviceModelDetailsType,
  useDeviceModel,
  useDeviceModelApiVersions,
  useEditDeviceModel,
  UseEditDeviceModelRequestPayload,
} from '@portals/api/partners';
import { IconPicker } from '@portals/core';
import { DEVICE_MODEL_ICONS, ModalProps } from '@portals/framework';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';
import {
  AuthMethodType,
  CommunicationProtocolType,
  DeviceModelIconName,
} from '@portals/types';
import { formatNumber } from '@portals/utils';

import { DASHBOARD_PATHS } from '../../routes/dashboard/dashboard-paths.constants';

const DEFAULT_TTL_THRESHOLD = 15;
const MAX_TTL_THRESHOLD = 99_999;
const MIN_TTL_THRESHOLD = 1;

export interface EditDeviceModelModalProps
  extends ModalProps<{ deviceModelId: string }> {}

export function EditDeviceModelModal({
  closeMe,
  data: { deviceModelId },
}: EditDeviceModelModalProps) {
  const deviceModel = useDeviceModel(deviceModelId);

  const isLoading = deviceModel.isInitialLoading || !deviceModel.data;

  return (
    <Modal
      opened
      size="xl"
      onClose={closeMe}
      title={<Text data-testid="configure-model-modal-title">Edit Model</Text>}
    >
      {isLoading ? (
        <LoadingOverlay visible />
      ) : (
        <EditDeviceModelForm deviceModel={deviceModel.data} onClose={closeMe} />
      )}
    </Modal>
  );
}

interface EditDeviceModelFormProps {
  deviceModel: DeviceModelDetailsType;
  onClose: VoidFunction;
}

function EditDeviceModelForm({
  deviceModel,
  onClose,
}: EditDeviceModelFormProps) {
  const navigate = useNavigate();

  const editDeviceModel = useEditDeviceModel();
  const apiVersions = useDeviceModelApiVersions();

  const form = useForm<UseEditDeviceModelRequestPayload['payload']>({
    initialValues: {
      name: deviceModel.model,
      sku: deviceModel.sku ?? '',
      auth_method: deviceModel.auth_method,
      communication_protocol:
        deviceModel.communication_protocol || CommunicationProtocolType.HTTPS,
      current_api_version: deviceModel.current_api_version,
      ttl_in_minutes: deviceModel.ttl_in_minutes,
      user_settings: deviceModel.user_settings,

      visible: deviceModel.visible,

      enable_configuration_device_tab:
        deviceModel.enable_configuration_device_tab,
      enable_state_device_tab: deviceModel.enable_state_device_tab,
      enable_telemetries_device_tab: deviceModel.enable_telemetries_device_tab,
      enable_details_device_tab: deviceModel.enable_details_device_tab,
      enable_events_device_tab: deviceModel.enable_events_device_tab,
      enable_files_device_tab: deviceModel.enable_files_device_tab,
    },
  });

  const [isTtlThresholdActive, setIsTtlThresholdActive] = useState(
    form.values.ttl_in_minutes !== null
  );

  const apiVersionsOptions = useMemo(() => {
    if (apiVersions.isLoading || !apiVersions.data) return [];

    return map(
      (version) => ({ value: version, label: version }),
      uniq([apiVersions.data?.latest, ...apiVersions.data.used])
    );
  }, [apiVersions.data, apiVersions.isLoading]);

  if (
    !form.values.current_api_version &&
    apiVersions.isFetched &&
    apiVersions.data
  ) {
    const { latest: latestAvailable, used: usedByModels } = apiVersions.data;

    form.setFieldValue(
      'current_api_version',
      usedByModels[0] || latestAvailable
    );
  }

  if (isTtlThresholdActive && form.values.ttl_in_minutes === null) {
    form.setFieldValue('ttl_in_minutes', DEFAULT_TTL_THRESHOLD);
  }

  const onSubmit = (values: typeof form.values) => {
    const requestPayload = { ...values };

    if (!isTtlThresholdActive) {
      requestPayload.ttl_in_minutes = null;
    }

    if (!requestPayload.sku) {
      requestPayload.sku = null;
    }

    editDeviceModel.mutate(
      {
        deviceModelId: deviceModel.id,
        payload: requestPayload,
      },
      {
        onSuccess: (editedDeviceModel) => {
          navigate(
            DASHBOARD_PATHS.dynamicPaths.deviceModel(editedDeviceModel.id)
          );

          onClose();
        },
      }
    );
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack spacing="xl" py="md">
        <Grid>
          <Grid.Col span={1}>
            <Input.Wrapper label="Icon">
              <IconPicker<DeviceModelIconName>
                icons={DEVICE_MODEL_ICONS}
                position="bottom"
                triggerSize={36}
                iconName={form.values.user_settings?.icon || 'Server'}
                onChange={(iconName) =>
                  form.setFieldValue('user_settings.icon', iconName)
                }
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span="auto">
            <TextInput
              required
              label="Model name"
              data-testid="model-name-input"
              size="sm"
              data-autofocus
              maxLength={60}
              {...form.getInputProps('name')}
            />
          </Grid.Col>
          <Grid.Col span="auto">
            <TextInput
              label="SKU (optional)"
              data-testid="model-sku-input"
              size="sm"
              {...form.getInputProps('sku')}
            />
          </Grid.Col>
        </Grid>

        <Divider variant="dashed" />

        {deviceModel.devices_count === 0 ? (
          <SimpleGrid cols={2}>
            <Select
              label="Authentication method"
              required
              rightSection={
                <Tooltip
                  label="See documentation"
                  position="right"
                  withinPortal={false}
                >
                  <Box
                    sx={(theme) => ({
                      cursor: 'pointer',
                      color: theme.colors.gray[6],
                    })}
                    onClick={() =>
                      window.open(
                        'https://dev.xyte.io/reference/set-authentication-method',
                        '_blank'
                      )
                    }
                  >
                    <QuestionCircle />
                  </Box>
                </Tooltip>
              }
              withinPortal={false}
              data={[
                { value: AuthMethodType.CloudId, label: 'Cloud ID' },
                {
                  value: AuthMethodType.MacSN,
                  label: 'MAC & Serial Number',
                },
                {
                  value: AuthMethodType.PubKey,
                  label: 'Pub Key',
                  disabled: true,
                },
                {
                  value: AuthMethodType.x509,
                  label: 'X.509 Certificate',
                  disabled: true,
                },
              ]}
              {...form.getInputProps('auth_method')}
            />

            <Select
              label="Communication protocol"
              withinPortal={false}
              required
              data={[
                {
                  value: CommunicationProtocolType.HTTPS,
                  label: 'HTTPS',
                },
                {
                  value: CommunicationProtocolType.MQTT,
                  label: 'MQTT',
                },
                {
                  value: CommunicationProtocolType.VPRO,
                  label: 'vPro (Coming Soon)',
                  disabled: true,
                },
              ]}
              {...form.getInputProps('communication_protocol')}
            />
          </SimpleGrid>
        ) : null}

        <SimpleGrid cols={2}>
          <Select
            label="XYTE API Version"
            disabled={apiVersionsOptions.length === 1 || deviceModel.active}
            withinPortal={false}
            required
            data={apiVersionsOptions}
            rightSection={
              <Tooltip
                label="We advise to select the latest API version that your device firmware supports"
                multiline
                width={300}
                position="top"
              >
                <Box
                  sx={(theme) => ({
                    cursor: 'pointer',
                    color: theme.colors.gray[6],
                  })}
                >
                  <QuestionCircle />
                </Box>
              </Tooltip>
            }
            {...form.getInputProps('current_api_version')}
          />
        </SimpleGrid>

        <Input.Wrapper
          label={
            <Group>
              <Text>Offline detection threshold</Text>

              <Switch
                checked={isTtlThresholdActive}
                data-testid="offline-detection-threshold-switch"
                onChange={(event) =>
                  setIsTtlThresholdActive(event.target.checked)
                }
              />
            </Group>
          }
          description={
            <Text mb="sm">
              When no data is sent from the device for this period (in minutes)
              the device will be marked as "offline" (between{' '}
              {MIN_TTL_THRESHOLD} and {formatNumber(MAX_TTL_THRESHOLD)})
            </Text>
          }
        >
          <Group spacing="xs">
            <NumberInput
              disabled={!isTtlThresholdActive}
              w={100}
              type="number"
              min={MIN_TTL_THRESHOLD}
              max={MAX_TTL_THRESHOLD}
              required={isTtlThresholdActive}
              value={form.values.ttl_in_minutes ?? undefined}
              onChange={(value) => {
                if (value === '') {
                  form.setFieldValue('ttl_in_minutes', undefined);
                } else {
                  form.setFieldValue('ttl_in_minutes', value);
                }
              }}
            />

            <Text color={isTtlThresholdActive ? 'gray.7' : 'gray.4'}>
              minutes
            </Text>
          </Group>
        </Input.Wrapper>

        <Divider variant="dashed" />

        <Input.Wrapper label={<Text mb="xs">Enabled tabs in device view</Text>}>
          <Stack align="start">
            <Switch
              styles={switchStyles}
              size="xs"
              label="Configuration"
              {...form.getInputProps('enable_configuration_device_tab', {
                type: 'checkbox',
              })}
            />

            <Switch
              styles={switchStyles}
              size="xs"
              label="State"
              {...form.getInputProps('enable_state_device_tab', {
                type: 'checkbox',
              })}
            />

            <Switch
              styles={switchStyles}
              size="xs"
              label="Telemetries"
              {...form.getInputProps('enable_telemetries_device_tab', {
                type: 'checkbox',
              })}
            />

            <Switch
              styles={switchStyles}
              size="xs"
              label="Details"
              {...form.getInputProps('enable_details_device_tab', {
                type: 'checkbox',
              })}
            />

            <Switch
              styles={switchStyles}
              size="xs"
              label="Events"
              {...form.getInputProps('enable_events_device_tab', {
                type: 'checkbox',
              })}
            />

            <Switch
              styles={switchStyles}
              size="xs"
              label="Files"
              {...form.getInputProps('enable_files_device_tab', {
                type: 'checkbox',
              })}
            />
          </Stack>
        </Input.Wrapper>

        <Divider />

        <Checkbox
          label="Show this model in the device claiming catalog"
          {...form.getInputProps('visible', { type: 'checkbox' })}
        />

        <Divider />
      </Stack>

      <Group position="right" pt="xl">
        <Button
          variant="outline"
          data-testid="configure-model-modal-close-button"
          onClick={onClose}
        >
          Close
        </Button>

        <Button
          color="primary"
          type="submit"
          disabled={!form.values.name}
          loading={editDeviceModel.isLoading}
          data-testid="configure-model-modal-submit-button"
        >
          Save changes
        </Button>
      </Group>
    </form>
  );
}

const switchStyles: SwitchProps['styles'] = {
  body: {
    display: 'grid',
    flexDirection: 'row-reverse',
    gridTemplateColumns: '100px max-content',
  },
  labelWrapper: {
    order: 1,
  },
  label: {
    paddingLeft: 0,
  },
  track: {
    order: 2,
  },
};
