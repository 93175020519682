import { Button, Modal, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { DeviceType, useUpdateDevice } from '@portals/api/partners';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

export interface DeviceNodeModalProps
  extends ModalProps<{ device: DeviceType }> {}

export function DeviceNoteModal({
  closeMe,
  data: { device },
}: DeviceNodeModalProps) {
  const updateDevice = useUpdateDevice();

  const form = useForm({
    initialValues: {
      noteText: device.note || '',
    },
  });

  function handleSubmit(values: typeof form.values) {
    updateDevice.mutate(
      { deviceId: device.id, partner_note: values.noteText },
      { onSuccess: closeMe }
    );
  }

  return (
    <Modal onClose={closeMe} opened title="Notes">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <ModalBody>
          <Textarea w="100%" minRows={20} {...form.getInputProps('noteText')} />
        </ModalBody>

        <ModalFooter position="right">
          <Button onClick={closeMe} variant="default">
            Cancel
          </Button>

          <Button type="submit" loading={updateDevice.isLoading}>
            Update
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
