import { Box, Button, createStyles, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';

import {
  convertWidgetFormToWidgetProps,
  DeviceLocationWidgetConfig,
  DeviceLocationWidgetFormTabs,
  DeviceLocationWidgetFormType,
  DeviceLocationWidgetPreview,
  getNextColorForListItem,
} from '@portals/device-widgets';
import { useConfirmationModal } from '@portals/framework';
import {
  getDeviceStatusIds,
  getDeviceStatusMetadata,
} from '@portals/framework/route-modals';

import { CommonWidgetFormWrapperProps } from './form-wrappers.types';

export function DeviceLocationWidgetFormWrapper({
  initialValues,
  onSubmit,
  onCancel,
  onClose,
  colors,
  onAddCustomColor,
}: CommonWidgetFormWrapperProps<DeviceLocationWidgetFormType>) {
  const { classes } = useStyles();
  const asyncConfirmationCheck = useConfirmationModal();

  const form = useForm<DeviceLocationWidgetFormType>({
    initialValues: {
      name: 'Device Location',
      show_location_name: true,
      color: 'red.4',
      ...(initialValues || {}),
    },
  });

  const [shouldDisplayDimensions, setShouldDisplayDimensions] = useState(
    !!form.values.dimensions?.length
  );
  const [activeTab, setActiveTab] = useState<DeviceLocationWidgetFormTabs>(
    DeviceLocationWidgetFormTabs.General
  );
  const [activeDimensionId, setActiveDimensionId] = useState(
    form.values.dimensions?.[0]?.id
  );

  const shouldDisplaySegmentedControl = Boolean(
    form.values.dimensions &&
      form.values.dimensions.length > 1 &&
      activeDimensionId &&
      shouldDisplayDimensions
  );

  const segmentedControlsDimensionsOptions = form.values.dimensions?.map(
    (dimension) => ({
      value: dimension.id,
      label: dimension.name,
    })
  );

  const {
    mapWidgetId,
    title,
    showLocationName,
    parentDeviceMarkerColor,
    stackProps,
    isDeviceOffline,
    lastUpdateTimestamp,
    points,
    base,
  } = convertWidgetFormToWidgetProps.device_location({
    formValues: form.values,
    activeDimensionId,
  });

  const handleChangeDisplayDimensions = (displayDimensions: boolean) => {
    setShouldDisplayDimensions(displayDimensions);

    if (displayDimensions && !form.values.dimensions?.length) {
      handleAddDimension();
    }
  };

  const handleAddDimension = () => {
    const isFirstDimension = !form.values.dimensions?.length;
    const newDimensionId = crypto.randomUUID();

    const firstRuleColoringRules = getDeviceStatusIds().map((status) => ({
      id: crypto.randomUUID(),
      value: status,
      color: getDeviceStatusMetadata(status).indicator,
    }));

    if (isFirstDimension) {
      form.setFieldValue('dimensions', [
        {
          id: newDimensionId,
          name: 'Status',
          telemetry_key: 'status',
          coloringRules: firstRuleColoringRules,
        },
      ]);
    } else {
      form.insertListItem('dimensions', {
        id: newDimensionId,
        name: 'New Dimension',
        telemetry_key: '',
        coloringRules: [
          {
            id: crypto.randomUUID(),
            value: '',
            color: getNextColorForListItem({
              colors,
              listLength: 0,
            }),
          },
        ],
      });
    }

    setActiveDimensionId(newDimensionId);
  };

  const handleRemoveDimension = (index: number) => {
    if (!form.values.dimensions) return;

    form.removeListItem('dimensions', index);

    const removedDimensionId = form.values.dimensions?.[index].id;

    if (activeDimensionId === removedDimensionId) {
      const fallbackDimensionId =
        form.values.dimensions?.find((v) => v.id !== removedDimensionId)?.id ||
        form.values.dimensions?.[0]?.id;

      setActiveDimensionId(fallbackDimensionId);
    }
  };

  const handleConfirmationDialog = async (): Promise<boolean> => {
    if (!form.isDirty()) {
      return true;
    }

    return asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
    });
  };

  const handleCloseWrapper = async () => {
    const isConfirmed = await handleConfirmationDialog();
    if (isConfirmed) onClose();
  };

  const handleCancelWrapper = async () => {
    const isConfirmed = await handleConfirmationDialog();
    if (isConfirmed) {
      onCancel();
    }
  };

  const getIsDimensionsFormValid = (values: typeof form.values) => {
    const locationWidgetDimensions = values.dimensions;

    let isValid = true;

    if (!locationWidgetDimensions || !locationWidgetDimensions.length) {
      return isValid;
    }

    locationWidgetDimensions.forEach((dimension, dimensionIndex) => {
      if (!dimension.name) {
        form.setFieldError(
          `dimensions.${dimensionIndex}.name`,
          'Name is required'
        );

        isValid = false;
      }

      if (!dimension.telemetry_key) {
        form.setFieldError(
          `dimensions.${dimensionIndex}.telemetry_key`,
          'Telemetry key is required'
        );

        isValid = false;
      }

      dimension.coloringRules.forEach((rule, ruleIndex) => {
        if (!rule.value) {
          form.setFieldError(
            `dimensions.${dimensionIndex}.coloringRules.${ruleIndex}.value`,
            'Value is required'
          );

          isValid = false;
        }
      });
    });

    return isValid;
  };

  const handleSubmit = (values: typeof form.values) => {
    const { dimensions, ...formValuesWithoutDimensions } = values;

    if (shouldDisplayDimensions) {
      if (getIsDimensionsFormValid(values)) {
        onSubmit(values);
      } else {
        setActiveTab(DeviceLocationWidgetFormTabs.ChildDevices);
      }
    } else {
      onSubmit(formValuesWithoutDimensions);
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Box className={classes.container}>
        <DeviceLocationWidgetConfig
          form={form}
          colors={colors}
          onAddCustomColor={onAddCustomColor}
          shouldDisplayDimensions={shouldDisplayDimensions}
          onChangeDisplayDimensions={handleChangeDisplayDimensions}
          onAddDimension={handleAddDimension}
          onRemoveDimension={handleRemoveDimension}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        <DeviceLocationWidgetPreview
          mapWidgetId={mapWidgetId}
          title={title}
          showLocationName={showLocationName}
          parentDeviceMarkerColor={parentDeviceMarkerColor}
          isDeviceOffline={!!isDeviceOffline}
          lastUpdateTimestamp={lastUpdateTimestamp}
          points={points}
          base={base}
          stackProps={stackProps}
          shouldDisplaySegmentedControl={shouldDisplaySegmentedControl}
          segmentedControlsDimensionsOptions={
            segmentedControlsDimensionsOptions
          }
          activeDimensionId={activeDimensionId}
          setActiveDimensionId={setActiveDimensionId}
          onClose={handleCloseWrapper}
        />

        <Group className={classes.footer} p="xl" position="right">
          <Button variant="default" onClick={handleCancelWrapper}>
            {initialValues ? 'Close' : 'Back'}
          </Button>

          <Button
            type="submit"
            data-testid={
              initialValues ? 'save-widget-button' : 'add-widget-button'
            }
          >
            {initialValues ? 'Save' : 'Add Widget'}
          </Button>
        </Group>
      </Box>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridTemplateRows: '1fr min-content',
    gridTemplateAreas: `
      "config preview"
      "footer footer"
    `,
    gap: 0,
    height: 790,
  },
  config: {
    gridArea: 'config',
    overflow: 'hidden',
    justifyContent: 'stretch',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    paddingInline: 0,
    paddingBlock: theme.spacing.xxl,
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    gridArea: 'footer',
  },
}));
